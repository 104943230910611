import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  getFirestore,
  collection,
  firestore,
  addDoc,
} from "firebase/firestore";
import {
  NewsletterContainer,
  NewsletterContent,
  NewsletterH3,
  NewsletterP,
  Form,
  FormLabel,
  FormInput,
  FormButton,
} from "./NewsletterElements";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    addDoc(collection(getFirestore(firestore), "subscriptions"), {
      email: email,
    });
    setEmail("");
    toast.success("Thanks for subscribing!");
  };

  return (
    <NewsletterContainer id="newsletter">
      <NewsletterContent>
        <NewsletterH3>Subscribe to the stoic Newsletter</NewsletterH3>
        <NewsletterP>
          Get emails with stoic resources, quotes, and early access to new blog
          posts.
        </NewsletterP>
        <Form id="newsletter" onSubmit={handleSubmit}>
          <FormLabel htmlFor="email">Email</FormLabel>
          <FormInput
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            placeholder="stoic@gmail.com"
            required
          />
          <FormButton type="submit" id="submit">
            Subscribe
          </FormButton>
          <Toaster
            toastOptions={{
              className: "toaster",
            }}
          />
        </Form>
      </NewsletterContent>
    </NewsletterContainer>
  );
};

export default Newsletter;
