import styled from "styled-components";

export const NewsletterContainer = styled.section`
  display: flex;
  justify-content: left;
  align-items: left;
  padding: 40px;
  margin: 40px auto;
  position: relative;

  @media screen and (max-width: 480px) {
    padding: 40px 2rem;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
`;

export const NewsletterContent = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: left;
`;

export const NewsletterH3 = styled.h3`
  font-size: 1.625rem;
  text-align: left;
  margin-top: -10px;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const NewsletterP = styled.p`
  margin-top: 4px;
  margin-bottom: 12px;
  font-weight: 400;

  @media screen and (max-width: 768px) {
    margin-bottom: 18px;
  }
`;

export const Form = styled.form`
  height: auto;
  width: 100%;
  z-index: 1;
  display: inline;
  margin: 0 auto;
`;

export const FormLabel = styled.label`
  display: none;
`;

export const FormInput = styled.input`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0.75rem 5.625rem 0.75rem 0.75rem;
  border: none;
  border-radius: 4px;
  flex-grow: 2;
  font-size: 1rem;
`;

export const FormButton = styled.button`
  cursor: pointer;
  margin-left: -5.625rem;
  padding: 7px;
  font-weight: 600;
  font-size: 0.875rem;
  border: none;
  border-radius: 4px;

  &:hover {
    -webkit-box-shadow: 0px 10px 13px -7px #999,
      1px 5px 15px 5px rgba(0, 0, 0, 0);
    box-shadow: 0px 10px 13px -7px #999, 1px 5px 15px 5px rgba(0, 0, 0, 0);
    transition: 0.4s all ease;
  }
`;
